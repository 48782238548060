import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';

import Colors from '../constants/Colors';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';

export default function RuleInfo({ path }: { path: string }) {
  return (
    <View>
      <View style={styles.getStartedContainer} >
        <Text style={styles.title}>Introduction</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
               This is a game of magic, strategy, and risk. You’ll gamble your own magic against your opponents as you attempt to dominate by magic or by might. Summon units and cast spells to conquer the map and push back your assailants. In this game you’ll need to think about where you move each unit to control resources and overpower your enemy. You also bring your own unique magic to the game, but the twist is that both you and your opponent can draw from the decks to summon those greater units or cast powerful spells. What you bring to the game could be your downfall, so choose wisely.
        </Text>

        <Text style={styles.title}>Set Up</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            Both you and your opponent will need to craft decks of 15 cards, you can include no more than five units in your individual deck and must fill the rest with spell cards. You may have no more than 2 of the same card name in a deck. Place these cards to your left of the gameboard with both opponents sitting across from each other. Your leaders start in opposite diagonal corners from each other where the mana is four diagonal spaces away from your leader.
        </Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
Next gather your pieces, you should have an item to represent yourself on the board as well as four miners, three scouts, three soldiers, four knights, four rangers, and three mages of each type. After both you and your opponent have gathered your items to represent these units make sure you also have items to represent the unique units in each of your decks. You don’t have to display these items, but you need to have them ready. Then record your health; both of you will start at twenty-five health points and zero mana/gold. Neither player can increase their health more than 25. The leader has an attack of two and move speed of two. The older wizard chooses who goes first.
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Winning the Game</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            There are two methods to win the game, the first is by magic and the second is by might. 
        </Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            Win by Magic: To win by magic you must gather sixty mana and five gold and then activate the tower in the middle of the map by moving your leader onto the square.
        </Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            Win by Might: To win by you might deplete your enemy’s health to zero. You simply overpower your enemy. A trapped leader with no units loses by default if they cannot move or summon 
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Summoning Units</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
                 You can summon units to assist you on the battlefield. On each turn; at any point, you may summon a single unit. There are three basic units you can summon for free; a Miner, a Soldier, or a Scout. At any time, you may only have up to four Miners, three Soldiers, and three Scouts on the board. There are also three advanced units you can cast for mana or gold; a Knight, a Ranger, or a Mage. At any time, you can only summon up to four Knights, four Rangers, and three Mages on the board. When summoning a mage, you can choose one of three types; a Fire Mage, an Ice Mage, or a Teleport Mage. Finally, you can summon a unit from a card. Only one unit can be summoned from a specific card, and once that unit dies the card goes to your graveyard.
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Casting Spells</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
           A wizard needs spells to gain advantage over his opponent. During your turn you may cast a spell from your hand, each spell is a one-use item meaning you’ll want to carefully decide when to use it. You may cast as many spells as you would like so long as you can afford to cast them.
        </Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
           A mage can cast their spells once every two turns. An ice mage may freeze one unit in place for one turn. A teleport mage may teleport to a square within 3x3 square. They can pass through objects while teleporting. The fire mage has the advantage of additional range in a straight line and gains this every turn.
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Movement</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            Each turn you may move yourself and any of your units up to the number of squares in a straight line based upon their movement speed. You may not move diagonally, therefore if you have a miner with a movement speed of three you may move up to three squares all in a straight path of its current location. You may not pass through an ally or any enemy unit. The table below shows the movement area of the miner in a single turn.
        </Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            If a unit decides to attack it can no longer move for the rest of the turn. It doesn’t matter if this is a melee or ranged attack. 
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Combat</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            A unit may defend itself or attack. On every turn a unit has one ranged attack if it has range. On your turn entering a space with a unit will engage a melee attack. Once you attack you cannot move. A unit has infinite melee defense. Ranged attacks are specific to units, the following are an example of a Fire Mage’s Straight range (Right) and a Ranger’s Diagonal range (Left)
        </Text>

        <View style={styles.imageView}>
        <Image
        style={styles.image}
        source={require('../images/Combat.png')}
        />
        </View>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            Even on an opponent’s turn; while an enemy is in range, you may attack with a ranged attack. Melee attacks can only be performed by moving your unit to the square where the enemy’s unit resides and thus can only be performed on your turn. When an enemy attacks you, then you may perform an unlimited number of melee defenses.
        </Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            Damage is performed by taking the attack power and subtracting that from the defense of the unit. The unit will heal at the end of every turn back to its full health except for the leader. If any unit is brought to zero health, it is removed from the battlefield. The leader cannot damage the other leader unless they would deal a killing blow and they themselves could survive the leader’s damage. Otherwise, they can just push each other one square.
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Gaining Resources</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            Only certain units may gather resources. A miner is the only unit that can gather gold and only the leader or a mage can gather mana. At the start of your turn if you have a miner on a gold square you gain one gold per miner on each gold square. At the start of your turn, you also will gain five mana per leader or mage on a mana circle.
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Constructing a Tower</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            A soldier, scout, knight, or ranger may construct a tower by entering in the same square as a miner. It takes until the start of your next turn for the tower to be complete and while the tower is being constructed/deconstructed you will add up the health of both units and divide them by two for its temporary health. Additionally, while being constructed/deconstructed the towers attack power is calculated by adding up the attack of both units and dividing by two. After the tower is constructed neither unit can move until it has been deconstructed which also takes until the start of your next turn to complete. The attack and defense of the tower; once complete, is the combined attack and defense of both units. It also increases the range for ranged attack by one or gives a one range with a ranged attack if the unit did not possess that ability before.
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

        <Text style={styles.title}>Drawing a Card</Text>

        <Text
          style={styles.baseText}
          lightColor="rgba(0,0,0,0.8)"
          darkColor="rgba(255,255,255,0.8)">
            On your turn you may spend two mana to draw a card from your deck or four mana to draw from your opponent’s deck. These cards go into your hand, and you can have a max of five cards in your hand.
        </Text>

        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

      </View>
    </View>
  );
}

function handleHelpPress() {
  WebBrowser.openBrowserAsync(
    'https://docs.expo.io/get-started/create-a-new-app/#opening-the-app-on-your-phonetablet'
  );
}

const styles = StyleSheet.create({
  getStartedContainer: {
    marginHorizontal: 150,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 18,
    marginBottom: 10
  },
  baseText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  imageView: {
    alignContent: 'center',
    justifyContent: 'center'
  },
  image: {
    alignSelf: 'center',
    width: 457, 
    height: 174
  }
});
