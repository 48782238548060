import { useState } from 'react'
import { StyleSheet, Button } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';

import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';

export default function TabOneScreen({ navigation }: RootTabScreenProps<'TabOne'>) {
  const [wizardOneGold, setWizOneGold] = useState(0);
  const [wizardOneHP, setWizOneHP] = useState(25);
  const [wizardOneMana, setWizOneMana] = useState(0);
  const [wizardOneValue, setWizOneValue] = useState(1);

  const [wizardTwoGold, setWizTwoGold] = useState(0);
  const [wizardTwoHP, setWizTwoHP] = useState(25);
  const [wizardTwoMana, setWizTwoMana] = useState(0);
  const [wizardTwoValue, setWizTwoValue] = useState(1);

  
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Wizard One</Text>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

      <View style={styles.buttonContainer}>
        
        <View style={styles.buttonStyle}>
        <Text style={styles.ResourceText}
        >Gold: {wizardOneGold}</Text>
        </View>
  
        <View style={styles.buttonStyle}>
        <Text style={styles.HPText}
        >HP: {wizardOneHP}</Text>
        </View>
  
        <View style={styles.buttonStyle}>
        <Text style={styles.ResourceText}
        >Mana: {wizardOneMana}</Text>
        </View>
  
      </View>

      <View style={styles.buttonContainer}>

      <View style={styles.buttonStyle}>
      <Button
        onPress={ () => {setWizOneGold(wizardOneGold + wizardOneValue)}}
        title="Add Gold"
        color="#841584"
      />
      </View>

      <View style={styles.buttonStyle}>
      <Button
        onPress={  () => {setWizOneHP(wizardOneHP + wizardOneValue)}}
        title="Add Health"
        color="#841584"
      />
      </View>

      <View style={styles.buttonStyle}>
      <Button
        onPress={ () => {setWizOneMana(wizardOneMana + wizardOneValue)}}
        title="Add Mana"
        color="#841584"
      />
      </View>

      </View>

      <View style={styles.buttonContainer}>

<View style={styles.buttonStyle}>
<Button
        onPress={  () => {setWizOneGold(wizardOneGold - wizardOneValue)}}
  title="Sub Gold"
  color="#841584"
/>
</View>

<View style={styles.buttonStyle}>
<Button
        onPress={  () => {setWizOneHP(wizardOneHP - wizardOneValue)}}
  title="Sub Health"
  color="#841584"
/>
</View>

<View style={styles.buttonStyle}>
<Button
        onPress={  () => {setWizOneMana(wizardOneMana - wizardOneValue)}}
  title="Sub Mana"
  color="#841584"
/>
</View>

</View>

      <View style={styles.buttonContainer}>
      <RNPickerSelect
      value={1}
            onValueChange={(value) => {setWizOneValue(Number(value))}}
            items={[
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
                { label: '6', value: 6 },
                { label: '7', value: 7 },
                { label: '8', value: 8 },
                { label: '9', value: 9 },
                { label: '10', value: 10 },
                { label: '11', value: 11 },
                { label: '12', value: 12 },
                { label: '13', value: 13 },
                { label: '14', value: 14 },
                { label: '15', value: 15 },
                { label: '16', value: 16 },
                { label: '17', value: 17 },
                { label: '18', value: 18 },
                { label: '19', value: 19 },
                { label: '20', value: 20 },
                { label: '21', value: 21 },
                { label: '22', value: 22 },
                { label: '23', value: 23 },
                { label: '24', value: 24 },
                { label: '25', value: 25 },
            ]}
        />

</View>

      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <Text style={styles.title}>Wizard Two</Text>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />

      <View style={styles.buttonContainer}>
        
        <View style={styles.buttonStyle}>
        <Text style={styles.ResourceText}
        >Gold: {wizardTwoGold}</Text>
        </View>
  
        <View style={styles.buttonStyle}>
        <Text style={styles.HPText}
        >HP: {wizardTwoHP}</Text>
        </View>
  
        <View style={styles.buttonStyle}>
        <Text style={styles.ResourceText}
        >Mana: {wizardTwoMana}</Text>
        </View>
  
      </View>

      <View style={styles.buttonContainer}>

      <View style={styles.buttonStyle}>
      <Button
        onPress={  () => {setWizTwoGold(wizardTwoGold + wizardTwoValue)}}
        title="Add Gold"
        color="#838996"
      />
      </View>

      <View style={styles.buttonStyle}>
      <Button
        onPress={  () => {setWizTwoHP(wizardTwoHP + wizardTwoValue)}}
        title="Add Health"
        color="#838996"
      />
      </View>

      <View style={styles.buttonStyle}>
      <Button
        onPress={  () => {setWizTwoMana(wizardTwoMana + wizardTwoValue)}}
        title="Add Mana"
        color="#838996"
      />
      </View>

      </View>

      <View style={styles.buttonContainer}>

<View style={styles.buttonStyle}>
<Button
        onPress={  () => {setWizTwoGold(wizardTwoGold - wizardTwoValue)}}
  title="Sub Gold"
  color="#838996"
/>
</View>

<View style={styles.buttonStyle}>
<Button
        onPress={  () => {setWizTwoHP(wizardTwoHP - wizardTwoValue)}}
  title="Sub Health"
  color="#838996"
/>
</View>

<View style={styles.buttonStyle}>
<Button
        onPress={  () => {setWizTwoMana(wizardTwoMana - wizardTwoValue)}}
  title="Sub Mana"
  color="#838996"
/>
</View>

</View>

      <View style={styles.buttonContainer}>
      <RNPickerSelect
      value={1}
            onValueChange={(value) => {setWizTwoValue(Number(value))}}
            items={[
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
                { label: '6', value: 6 },
                { label: '7', value: 7 },
                { label: '8', value: 8 },
                { label: '9', value: 9 },
                { label: '10', value: 10 },
                { label: '11', value: 11 },
                { label: '12', value: 12 },
                { label: '13', value: 13 },
                { label: '14', value: 14 },
                { label: '15', value: 15 },
                { label: '16', value: 16 },
                { label: '17', value: 17 },
                { label: '18', value: 18 },
                { label: '19', value: 19 },
                { label: '20', value: 20 },
                { label: '21', value: 21 },
                { label: '22', value: 22 },
                { label: '23', value: 23 },
                { label: '24', value: 24 },
                { label: '25', value: 25 },
            ]}
        />

</View>

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: 32,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10
  },
  separator: {
    height: 1,
    width: '80%',
  },
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 5
  },
  buttonStyle: {
    marginHorizontal: 20,
    marginTop: 5
  },
  HPText: {
    fontSize: 28,
    fontWeight: 'bold',
    justifyContent: 'center'
  },
  ResourceText: {
    fontSize: 24,
    fontWeight: 'bold',
    justifyContent: 'center'
  }
});